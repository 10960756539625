<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12">
                            <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('grade_conversion_name')">
                                    <b-form-input
                                        v-model="defineForm.name"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import GradeConversionService from "@/services/GradeConversionService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            defineForm: {
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("grade_conversion_update_title")
        }
    },
    methods: {
        getFormData() {
            GradeConversionService.get(this.id).then(response => {
                this.defineForm = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                const formData = {
                    name: this.defineForm.name,
                }
                GradeConversionService.update(this.id, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('hide')
                }).catch(e => {
                    this.showErrors(e,this.$refs.updateForm)
                })
            }
        }
    },
    watch: {
        id: {
            handler: function (val) {
                this.getFormData(val)
            }
        }
    }
};
</script>
